import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"

import Seo from "../components/Seo"
import { Layout } from "../components/Layout"

const FlowPage: React.FC = () => {
  const pageName: string = "工事の流れ";

  return (
    <Layout pageName={pageName}>
      <Seo title={`${pageName} | メープル・シーリング`} />
      <Container>
        <section>
          <Row>
            <Col xs={12}>
              <h2 className="subtitle">工事完了までのステップ</h2>
              <p>
                お問い合わせをいただいてから工事終了まで、迅速な対応とお客様との円滑なコミュニケーションを心がけております。
                <br />
                お客様のご都合に合わせて誠実に対応させていただきます。
              </p>
            </Col>
          </Row>
          <Row className="flow-step">
            <Col md={3} className="flow-step-head">
              <StaticImage
                src="../images/flow-step-icon_001.png"
                alt="STEP.1"
                className="flow-step-icon"
              />
              <span className="flow-step-number">STEP. 1</span>
              <h3 className="flow-step-title">お問い合わせ</h3>
            </Col>
            <Col md={9} className="flow-step-body">
              <Row>
                <Col md={7}>
                  <p className="flow-step-text">
                    まずはお電話またはお問い合わせフォームからお気軽にご連絡ください。お急ぎの方はお電話にてご連絡ください。折り返し当社から電話にてご連絡差し上げます。お客様のご希望の調査の日程を調整いたします。
                  </p>
                  <Link to="/contact/" className="btn-contact btn-effect">
                    無料相談・お問い合わせ
                  </Link>
                </Col>
                <Col md={5} className="flow-step-img">
                  <StaticImage
                    src="../images/flow-step_001.png"
                    alt="お問い合わせ"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="flow-step">
            <Col md={3} className="flow-step-head">
              <StaticImage
                src="../images/flow-step-icon_002.png"
                alt="STEP.2"
                className="flow-step-icon"
              />
              <span className="flow-step-number">STEP. 2</span>
              <h3 className="flow-step-title">調査</h3>
            </Col>
            <Col md={9} className="flow-step-body">
              <Row>
                <Col md={7}>
                  <p className="flow-step-text">
                    お客様の気になっておられる点やお家の現状をお伺いし、症状に応じて建物の調査を行います。施工箇所の実測を行います。
                  </p>
                </Col>
                <Col md={5} className="flow-step-img">
                  <StaticImage src="../images/flow-step_002.png" alt="調査" />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="flow-step">
            <Col md={3} className="flow-step-head">
              <StaticImage
                src="../images/flow-step-icon_003.png"
                alt="STEP.3"
                className="flow-step-icon"
              />
              <span className="flow-step-number">STEP. 3</span>
              <h3 className="flow-step-title">ご提案</h3>
            </Col>
            <Col md={9} className="flow-step-body">
              <Row>
                <Col md={7}>
                  <p className="flow-step-text">
                    調査結果についてお客様にご説明し、お客様のお家に一番適した施工方法のご提案をご説明いたします。
                    <br />
                    お見積もりに関して疑問に思われる点がありましたら丁寧にご説明いたします。
                  </p>
                  <p className="flow-step-text text-bold mt-1">
                    ※雨漏りの状況やお家の形状ゆえに、シーリング工事や各種防水工事が適さない場合もあります。当社では無理な施工は致しませんので、対応しかねる場合もございます。ご了承ください。
                  </p>
                </Col>
                <Col md={5} className="flow-step-img">
                  <StaticImage src="../images/flow-step_003.png" alt="ご提案" />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="flow-step">
            <Col md={3} className="flow-step-head">
              <StaticImage
                src="../images/flow-step-icon_004.png"
                alt="STEP.4"
                className="flow-step-icon"
              />
              <span className="flow-step-number">STEP. 4</span>
              <h3 className="flow-step-title">ご契約</h3>
            </Col>
            <Col md={9} className="flow-step-body">
              <Row>
                <Col md={7}>
                  <p className="flow-step-text">
                    契約書には工事内容、予算、工事期間などの必要事項を全て明記した上でお渡しさせて頂きます。
                  </p>
                </Col>
                <Col md={5} className="flow-step-img">
                  <StaticImage src="../images/flow-step_004.png" alt="ご契約" />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="flow-step">
            <Col md={3} className="flow-step-head">
              <StaticImage
                src="../images/flow-step-icon_005.png"
                alt="STEP.5"
                className="flow-step-icon"
              />
              <span className="flow-step-number">STEP. 5</span>
              <h3 className="flow-step-title">施工</h3>
            </Col>
            <Col md={9} className="flow-step-body">
              <Row>
                <Col md={7}>
                  <p className="flow-step-text">
                    近隣にご迷惑にならないよう注意して作業致しますのでご安心ください。工事中に気になる事がありましたらお気軽にお申しつけください。
                  </p>
                  <p className="flow-step-text text-bold mt-1">
                    ※工事中、乾燥待ちの期間に、一時的にバルコニーやベランダが使用できなくなったり、お客様の大切な衣服や所持品を汚さないために接触注意箇所が生じることがあります。お客様と円滑なコミュニケーションを心がけ、上記の注意点や工事進行状況などを報告致します。
                  </p>
                </Col>
                <Col md={5} className="flow-step-img">
                  <StaticImage src="../images/flow-step_005.png" alt="施工" />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="flow-step">
            <Col md={3} className="flow-step-head">
              <StaticImage
                src="../images/flow-step-icon_006.png"
                alt="STEP.6"
                className="flow-step-icon"
              />
              <span className="flow-step-number">STEP. 6</span>
              <h3 className="flow-step-title">工事完了</h3>
            </Col>
            <Col md={9} className="flow-step-body">
              <Row>
                <Col md={7}>
                  <p className="flow-step-text">
                    お引渡し前に検査を当社で行い、お客様に完了報告をいたします。ご希望の際は、お客様にご一緒に確認していただく事も可能です。
                  </p>
                </Col>
                <Col md={5} className="flow-step-img">
                  <StaticImage
                    src="../images/flow-step_006.png"
                    alt="工事完了"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
      </Container>
    </Layout>
  )
}

export default FlowPage
